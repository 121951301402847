<template>
  <div class="box">
    <el-table
      class="loading"
      v-if="loading"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      :style="{ width: '100%', height: '100%' }"
    >
    </el-table>
    <my-header :position="'relative'"></my-header>
    <div class="navbar">
      <span @click="$router.push('/orderList')">我的订单</span>
      <i class="el-icon-arrow-right"></i>
      <span>订单详情</span>
    </div>
    <div class="detail-box">
      <div class="header">
        <p class="title">订单信息</p>
        <div class="header-center">
          <div>
            <span>
              <span>订单号:</span>
              <span>{{ detail.id }}</span>
            </span>
            <span>
              <span>创建时间:</span>
              <span>{{ detail.createTime }}</span>
            </span>
          </div>
          <div>
            <span>收件人:</span>
            <span>{{ site.receiverName }}</span>
            <span>{{ detail.address }}</span>
          </div>
          <div>
            <span>订单状态:</span>
            <span
              v-text="
                detail.state == 0
                  ? '已取消'
                  : detail.state == 10
                  ? '待支付'
                  : detail.state == 20
                  ? '已付款'
                  : detail.state == 40
                  ? '已发货'
                  : detail.state == 50
                  ? '已完成'
                  : '订单关闭'
              "
            ></span>
          </div>
          <div>
            <span>支付方式:</span>
            <span
              v-text="
                detail.payState == 1
                  ? '先后付清'
                  : detail.payState == 2
                  ? '一次付清'
                  : '未知'
              "
            ></span>
          </div>
          <div class="submit" v-if="detail.state == 10">
            <span @click="pay(detail)">去支付</span>
            <span>取消订单</span>
          </div>
        </div>
      </div>
      <div class="center">
        <p class="title">商品清单</p>
        <div>
          <el-row class="title-title">
            <el-col :span="14">
              <div class="left">商品信息</div>
            </el-col>
            <el-col :span="10">
              <div class="right">
                <span>单价</span>
                <span>数量</span>
                <span>合计</span>
                <span>状态</span>
              </div>
            </el-col>
          </el-row>
          <div class="item-box" v-if="detail.orderItemVO">
            <div v-for="(v, k) of detail.orderItemVO" :key="k">
              <el-row class="item-box-item">
                <el-col :span="14">
                  <div class="left">
                    <img :src="v.productImg" alt="" />
                    <span>{{ v.productName }}</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div class="right">
                    <span>{{
                      `￥${v.totalPrice / v.productNum}`
                    }}</span>
                    <span>{{ v.productNum }}</span>
                    <span>{{ `￥${v.totalPrice}` }}</span>
                    <span>{{
                      v.state == 0
                        ? "已取消"
                        : v.state == 10
                        ? "未支付"
                        : v.state == 20
                        ? "已付款"
                        : v.state == 40
                        ? "已发货"
                        : v.state == 50
                        ? "已完成"
                        : "已关闭"
                    }}</span>
                    <span class="shouhuo" v-if="v.state == 40">确认收货</span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="price-box">
              <span>订单总价:</span>
              <span class="price">{{
                `￥${detail.totalPrice}`
              }}</span>
            </div>
          </div>
          <div class="item-box" v-if="!detail.orderItemVO">
            <div>
              <el-row class="item-box-item">
                <el-col :span="14">
                  <div class="left">
                    <img :src="detail.productImg" alt="" />
                    <span>{{ detail.productName }}</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div class="right">
                    <span>{{
                      `￥${detail.totalPrice / detail.productNum}`
                    }}</span>
                    <span>{{ detail.productNum }}</span>
                    <span>{{ `￥${detail.totalPrice}` }}</span>
                    <span>{{
                      detail.state == 0
                        ? "已取消"
                        : detail.state == 10
                        ? "未支付"
                        : detail.state == 20
                        ? "已付款"
                        : detail.state == 40
                        ? "已发货"
                        : detail.state == 50
                        ? "已完成"
                        : "已关闭"
                    }}</span>
                    <span class="shouhuo" v-if="detail.state == 40">确认收货</span>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="price-box">
              <span>订单总价:</span>
              <span class="price">{{
                `￥${detail.totalPrice}`
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myHeader from "../my-header.vue";
export default {
  components: { myHeader },
  data() {
    return {
      // 是否显示加载中
      loading: false,
      detail: {},
      site: {},
    };
  },
  methods: {
    async getDetails() {
      this.detail = JSON.parse(this.$route.query.item);
      console.log(this.detail);
      const res = await this.$http({
        method: "get",
        url: `front/shipping/findById?id=${this.detail.shippingId}`,
      });
      if (res.data.status == 200) {
        this.site = res.data.data;
      }
    },
    // 支付
    async pay(v) {
      console.log(v.id);
      this.loading = true;
      const res = await this.$http({
        method: "post",
        url: `front/pay/scanCodePay`,
        data: {
          orderNo: v.id,
        },
      });
      console.log(v);
      console.log(res.data.data);
      if (res.data.status) {
        this.$router.push({
          path: "/pay",
          query: {
            id: JSON.stringify(v.id),
            url: JSON.stringify(res.data.data.qrUrl),
          },
        });
        this.loading = false;
      }
    },
  },
  created() {
    this.getDetails();
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  background: #eeeeee;
  line-height: 50px;
  font-size: 14px !important;
  color: #888;
  span {
    cursor: pointer;
  }
  span:hover {
    color: #c60023;
  }
  span:nth-child(1) {
    display: inline-block;
    margin-left: 15%;
    font-weight: bold;
  }
}
.box {
  font: 12px/1.5 tahoma, arial, Hiragino Sans GB, \\5b8b\4f53, sans-serif;
  background: #f6f6f6;
  & ::v-deep .el-loading-mask {
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .detail-box {
    padding: 15px;
    width: 80%;
    margin: 20px auto;
    .header {
      background: #fff;
      padding: 15px;
      margin-bottom: 20px;
      .title {
        padding: 10px;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        color: #666;
        font-weight: 700;
      }
      .header-center {
        span {
          padding: 3px;
        }
        padding: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        div {
          padding: 7px;
        }
        .submit {
          span {
            display: inline-block;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            padding: 2px 10px;
            margin-right: 10px;
            background-color: #c60023;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
    .center {
      padding: 15px;
      background: #fff;
      .title {
        background: #fff !important;

        padding: 10px;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        color: #666;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .el-row > div:not(:nth-child(1)) {
        margin: 0;
      }
      .title-title {
        background: #eeeeee;
        color: #666;
        font-weight: 700;
        .left {
          margin-left: 150px;
          line-height: 45px;
        }
        .right {
          height: 100%;
          display: flex;

          text-align: center;
          span {
            width: 21%;
            height: 100%;
            line-height: 45px;
          }
        }
      }
      .item-box {
        .centertitle {
          padding: 10px;
          background: #eeeeee;
          margin-top: 20px;
          .left {
            color: #999;
            & > span:nth-child(2) {
              font-weight: 700;
              cursor: pointer;
            }
            & > span:nth-child(2):hover {
              color: #e80012;
            }
          }
          .center {
            color: #999;
            display: flex;
            justify-content: space-around;
          }
          .right {
            color: #999;
            display: flex;
            justify-content: space-around;
            text-align: right;
            & span {
              font-weight: 700;
              cursor: pointer;
              i {
                font-weight: 700;
              }
              &:hover {
                color: #e80012;
              }
            }
          }
        }
      }
      .item-box-item {
        border-bottom: 1px solid #eee;
        height: 100px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
            margin: 9px;
            margin-left: 30px;
            border: 1px solid #ddd;
          }
          span {
            margin-left: 15px;
            color: #999;
          }
        }
        .right {
          color: #666;
          height: 100px;
          display: flex;

          align-items: center;
          text-align: center;
          span {
            width: 21%;
          }
          .shouhuo {
            width: 11%;
            display: inline-block;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            padding: 2px 5px;
            background-color: #c60023;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .price-box {
        padding: 10px;
        text-align: right;
        span:nth-child(1) {
          font-size: 14px;
        }
        .price {
          font-size: 14px;
          line-height: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
          font-weight: 700;
          color: #c60023;
        }
      }
    }
  }
}
</style>